.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  color: $color-tiffany-blue;
  border-color: $color-tiffany-blue;
}

.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  background-color: $color-tiffany-blue;
  border-color: $color-tiffany-blue;
  color: white;
}



.module-detail {
  .ant-select-selector {
    padding: 0 !important;
    .ant-select-selection-item {
      color: white !important;
    }
  }
}

.ant-checkbox-wrapper {
  color: rgba(0, 0, 0, 0.65);
}

.ant-table-cell::before {
  width: 0 !important; // Removes bar between column headers
}

.mapped-route-modal {
  .ant-modal-content {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.16);
    .ant-modal-body {
      padding: 0;
    }
  }
}

.photo-gallery-modal {
  top: 28px;
  .ant-modal-confirm-content {
    margin-left: 0px !important;
  }
  .anticon-info-circle {
    display: none !important;
  }
  .ant-modal-close {
    display: inherit;
    top: 2.5%;
  }
  .ant-confirm-btns {
    display: none;
  }
  .ant-confirm-body > i {
    display: none;
  }
  .ant-confirm-title {
    font-weight: 600;
  }
  .ant-confirm-content {
    margin: 15px 0 0 0;
  }
  .ant-confirm-btns {
    .ant-btn {
      color: $color-timberwolf;
      border: 1px solid $color-timberwolf;
      i.anticon.anticon-spin.anticon-loading {
        color: white !important;
        display: inline-block;
      }
    }
    .ant-btn-danger {
      width: 150px;
      color: white;
      background-color: red;
    }
  }

  .display-image-lrg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    min-height: 400px;

    #image-wrapper img {
      box-shadow: 5px 10px 18px #ccc;
      -webkit-box-shadow: 5px 10px 18px #ccc;
      -moz-box-shadow: 5px 10px 18px #ccc;
    }
  }
  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    div.carousel {
      width: 400px;
    }
    .carousel-button {
      margin: -40px 10px 0 10px;
    }
  }
}
.death-lists-filter {
  display: inline-block;
  margin-left: 6px;
  margin-right: 3px;
  vertical-align: middle;
}

.ant-tabs-top {
  .ant-tabs-nav {
    margin: 0;

    &::before {
      border-bottom: 1px solid #e6e6e6;
    }

    .ant-tabs-nav-list {
      margin: 0;

      .ant-tabs-tab {
        border-top-width: 1.5px;
        font-weight: 500;
      }

      .ant-tabs-tab:last-child {
        margin-right: 0px;
      }

      .ant-tabs-tab-active {
        border: 1px solid #e6e6e6;
        border-bottom: none;
        .ant-tabs-tab-btn {
          color: black;
          text-shadow: none;
        }
      }

      .ant-tabs-tab:not(.ant-tabs-tab-active) {
        color: #8e908c;
        border-bottom: 1px;
        border-bottom-width: 1.5px;
        border-bottom-style: solid;
        border-bottom-color: rgb(232, 232, 232);
      }

      .ant-tabs-nav-scroll {
        float: right;
      }
    }
  }
}

.leftTabs .ant-tabs-nav-scroll {
  float: left;
}

.ahi-form .ant-select.inactive .ant-select-selector {
  background-color: #c8c8c8 !important;
}

@media (max-width: 768px) {
  .ant-table-body {
    font-size: 70% !important;
  }
}

.ant-carousel {
  .slick-slide {
    text-align: center;
    line-height: 100px;
    overflow: hidden;

    img {
      display: inline-flex !important;
      vertical-align: inherit !important;
    }
  }
}
.module-detail {
  .ant-select-selection__rendered {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ant-select-selection-selected-value {
    padding-right: 0 !important;
  }
}

.detail-page {
  .ant-divider.ant-divider-horizontal {
    margin-top: 12px;
  }
}

.ant-tabs-extra-content {
  float: left !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $color-tiffany-blue;
  border-color: $color-tiffany-blue;
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $color-tiffany-blue;
}

.ant-checkbox-input:hover + .ant-checkbox-inner {
  border-color: $color-tiffany-blue;
}

.custom-table {
  .ant-table-thead {
    background-color: #fff;

    th {
      background-color: #fff;
      border-bottom: 3px solid $color-timberwolf;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.65);
      @media (max-width: 1024px) {
        font-size: 10px;
      }

      span {
        white-space: nowrap;
      }

      .order-sorter {
        .anticon {
          color: rgba(0, 0, 0, 0.3);
        }

        &.asc {
          .anticon-caret-up {
            color: rgba(0, 0, 0, 0.65);
          }
        }

        &.desc {
          .anticon-caret-down {
            color: rgba(0, 0, 0, 0.65);
          }
        }

        .icon-container {
          display: inline-block;
          margin-left: 6px;
          vertical-align: middle;
          cursor: pointer;

          .anticon {
            font-size: 7px;
            display: block;
          }
        }
        .icon-calendar {
          display: inline-block;
          margin-left: 6px;
          margin-right: 3px;
          vertical-align: middle;
          cursor: pointer;
        }
      }
      .icon-calendar {
        display: inline-block;
        margin-left: 8px;
        margin-right: 3px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }

  .ant-table-tbody {
    background-color: #fff;

    tr {
      td {
        border-bottom: 1px solid $color-timberwolf;

        @media (max-width: 1024px) {
          font-size: 10px;
        }

        a {
          color: $color-tiffany-blue;
        }
      }
    }
  }

  .custom-filter-dropdown {
    border-radius: 6px;
    background: #fff;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    width: 219px;
  }
}

.ant-picker-input {
  input {
    text-align: center;
  }
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: $color-tiffany-blue;
}

.inactive.ant-select-open {
  .ant-select-selection-item {
    color: black;
  }
}

.mapped-route-table {
  table {
    width: 498px;
  }

  .ant-table-thead {
    th {
      background-color: white;
      border-bottom: 3px solid $color-timberwolf;
      font-size: 10px;
      padding-bottom: 4px;
      
      &:first-child {
        padding-left: 40px;
      }

      .order-sorter {
        :hover {
          cursor: pointer;
        }
        .anticon {
          color: #283546;
        }

        &.asc {
          .anticon-caret-up {
            opacity: .3;
          }
        }

        &.desc {
          .anticon-caret-down {
            opacity: .3;
          }
        }

        .icon-container {
          display: inline-block;
          margin-left: 6px;
          vertical-align: middle;

          .anticon {
            font-size: 7px;
            display: block;
          }
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      &:hover {
        .table-icon {
          visibility: visible !important;
        }
      }

      td,
      :nth-child(2) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      td {
        border-bottom: 1px solid $color-timberwolf;
        text-align: right;
        padding: 8px 16px 8px 16px;
        font-size: 14px;
      }
    }
  }

  .ant-pagination::after {
    content: none;
  }
  .ant-table-pagination {
    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-item-link {
      border-radius: 6px;
    }
    .ant-pagination-prev {
      margin-right: 24px;
    }
    .ant-pagination-next {
      margin-left: 16px;
    }
  }
}

.upload-pen-coordinate-table {
  margin-bottom: 2px;
  height: 212px;
  overflow-y: auto;

  .ant-table-thead {
    background-color: rgba(235, 235, 235, 0.24);

    th {
      color: rgba(0, 0, 0, 0.87);
      font-size: 10px;
      font-weight: normal;
      padding: 7px;
      padding-left: 11px;
      margin: 0px;
    }
  }

  .ant-table-tbody {
    background-color: rgba(235, 235, 235, 0.24);

    tr {
      td {
        color: rgba(0, 0, 0, 0.87);
        padding-top: 8px;
        padding-bottom: 8px;
        vertical-align: top;

        @media (max-width: 248px) {
          font-size: 10px;
        }
      }
    }
  }

  td:first-child,
  th:first-child {
    border-right: 1px solid $color-table-dividing-line;
  }

  tr {
    border-bottom: 1px solid $color-table-dividing-line;
  }

  tbody > tr:last-child > td {
    border-bottom: none;
  }
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 4px 4px 0px 0px;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  transition: none;
}

.close-button-icon > span > svg {
  height: 6px;
  width: 6px;
  color: white;
}

.ant-input:focus,
.ant-input:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $color-tiffany-blue;
}

.ant-spin-spinning .ant-spin-dot .ant-spin-dot-item {
  background-color: $color-tiffany-blue;
}

// Table Pagination global override
.ant-pagination-item-active {
  border-color: $color-tiffany-blue;

  a {
    color: $color-tiffany-blue;
    border-color: $color-tiffany-blue;
  }
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item-link {
  &:hover,
  &:focus {
    border-color: $color-tiffany-blue;

    a {
      color: $color-tiffany-blue;
      border-color: $color-tiffany-blue;
    }
  }
}