.list-page {
  .list-page-top-bar {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 12px;
    .current-role {
      text-transform: uppercase;
    }

    .ant-select {
      min-width: 160px !important;
    }

    .filter-label {
      margin-right: 20px;
    }
  }

  .list-filter-dropdown {
    color: #000;
    font-weight: 600;
    text-decoration: none !important;

    .anticon-caret-down {
      font-size: 9px;
      margin-left: 8px;
    }

    &:active {
      color: $color-tiffany-blue;
    }

    &:hover {
      color: $color-tiffany-blue;
    }
  }

  &.tabbed-list {
    .tab-icon {
      margin-right: 8px;
    }
    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      img.tab-icon {
        opacity: 0.5;
      }
    }
    .ant-table-body {
      margin-top: 0px !important;
    }
  }

  .facility-list {
    .ant-table-tbody {
      margin-top: 12px;

      tr {
        cursor: pointer;
      }
    }
  }
}

.table-modal-button-style {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: $color-tiffany-blue;
}
