.full-width-button {
  width: 100%;
  margin: 10px 0;
}

.small-button {
  padding: 0 8px;
  height: 26px;

  span {
    font-size: 13px;
  }
}

.next-to-top-nav {
  float: right;
}

.form-button {
  border: 1px solid black;
}

.ant-switch-checked {
  background-color: #00c9b0 !important;
}

.mapped-route-modal-carousel-button {
  width: 28px;
  height: 88px;
  background: rgba(0, 0, 0, 0.04);
  border: none;
  border-radius: 2px;
}

.black-and-white-button {
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: #fff;
}

.disabled-button {
  background: #dedede;
  border-color: #dedede;
  color: rgba(0, 0, 0, 0.4);
}

.save-button {
  background: $color-tiffany-blue;
  border-color: $color-tiffany-blue;
  border-style: none;
  border-radius: 4px;
  height: 25px;
  width: 51px;
  color: white;
}

#google-maps-reset-zoom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-dark-grey;
  border-radius: 24px;
  height: 32px;
  width: 97px;
  margin-bottom: 24px;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  border: none;
  cursor: pointer;
}
