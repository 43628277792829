.upload-kml-form {
    padding: 5%;
    display: flex;
    flex-direction: column;
    border-top: none;
    margin-top: 0px;
    border-radius: 2px;

    .top-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 26px;

        .upload-button {
            width: 122px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .clear-button {
            height: 12px;
            width: 12px;
            border: none;
            background-color: gray;
            margin-left: 8px;
            border-radius: 50%;
        }
    }
    .pen-coordinate-table-container {
        border-top: 1px solid rgba(0, 0, 0, 0.25); 
    }

    .ant-form {
        border: 1px solid #e6e6e6;
        border-top: none;
    }
}