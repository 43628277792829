//Colors
$color-ant-has-errors: #f5222d;
$color-form-error: #FE3824;
$color-medium-jungle-green: rgba(45, 45, 45, 1);
$color-dark-jungle-green-transparent: rgba(37, 37, 37, 0.2);
$color-nav-list-grey: #919191;
$color-search-box-bg: #4c4c4c;
$color-table-dividing-line: #e7e7e7;
$color-tiffany-blue: rgba(0, 201, 176, 1);
$color-timberwolf: rgba(215, 215, 215, 1);
$color-transparent: rgba(0, 0, 0, 0);
$color-white-smoke: rgba(245, 245, 245, 1);
$color-troller-grey: rgba(124, 124, 124, 0.5);
$color-dark-grey: #1a1515;

$movements-green: #14869b;

$truck-system-green: #00b1bd;

//Color Aliases
$color-body-bg: $color-white-smoke;
$color-nav-bg: $color-medium-jungle-green;
$color-warning-red: $color-ant-has-errors;
