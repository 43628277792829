.ahi-form {
  background-color: #fff;
  margin-top: 28px;
  padding: 5%;
  border: 1px solid $color-dark-jungle-green-transparent;
  border-radius: 6px;

  .add-user-form {
    background-color: #fff;
    margin-top: 28px;
    padding: 5%;
    border: 1px solid $color-dark-jungle-green-transparent;
    border-radius: 6px;
  }

  h3 {
    text-align: left;
    text-transform: uppercase;
    font-weight: 800;
  }

  .ant-row {
    text-align: left;
  }

  .ant-divider {
    margin: 16px 0;
  }

  .ant-form-item-label {
    line-height: 20px;

    label {
      font-size: 10px;
      text-transform: uppercase;
      color: $color-nav-list-grey;
    }
  }

  .ant-form-item {
    display: block;
  }

  .ant-select {
    &.active {
      .ant-select-selector {
        background-color: $color-tiffany-blue;
        .ant-select-selection-item {
          color: #fff !important;
        }
      }
      .ant-select-arrow {
        color: #fff;
      }
    }
  }

  .ahi-form-bottom-button-wrap {
    text-align: right;

    .ant-btn {
      height: 28px;
      margin: 0 4px;
      line-height: 1.499;
      border-radius: 4px;
    }

    .ant-btn-danger {
      float: left;
      margin-top: 6px;
    }
  }

  .in-app-button {
    margin-right: 10px;

    &.active {
      background-color: yellow;
    }
  }

  .email-invitation-text {
    text-align: left;
    color: $color-nav-list-grey;
  }

  .display-passwords {
    display: flex;
  }

  .hide-passwords {
    display: none;
  }

  .error-text {
    color: $color-form-error;
  }

  .error-block {
    display: flex;
    margin-bottom: 24px;
    align-items: left;

    span {
      text-align: left;
    }
  }
}
