@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');

p,
a,
li,
span,
h1,
h2,
h3,
h4,
h5,
h6,
b,
i,
div,
input,
textarea {
  font-family: 'Roboto', sans-serif;
}

.font-bold {
  font-weight: bold;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-muted {
  color: $color-troller-grey;
}

.opaque-text {
  color: #000000;
  opacity: 0.56;
}

.upload-file-text {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.48);
  margin-left: 12px;
}

.ellipsized {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-with-black-border {
  -webkit-text-stroke: .9px black; /* width and color */
  font-family: 'Roboto', sans-serif;
}
