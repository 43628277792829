nav.site-navigation {
  background-color: $color-nav-bg;
  margin-bottom: 12px;
  min-height: 51px;

  .nav-left {
    ul {
      list-style-type: none;
      margin: 0;
      padding-left: 0;

      li {
        padding: 1px 0;
        display: inline-block;
        margin: 12px 10px 6px 10px;
        color: #fff;

        a {
          color: $color-nav-list-grey;
          font-size: 15px;

          @media (max-width: 1024px) {
            font-size: 12px;
          }
        }

        &.active {
          border-bottom: 2px solid $color-tiffany-blue;

          a,
          i {
            color: $color-tiffany-blue;
          }
        }
      }
    }
    .active-back,
    .active-back > a {
      color: white;
    }
    .active-back > a > i {
      color: white;
      font-weight: 800;
    }
  }

  .nav-center {
    .nav-search {
      width: 85%;
      display: block;
      margin: auto;
      margin-top: 9px;

      .ant-input-affix-wrapper {
        background-color: $color-search-box-bg;
        border-color: $color-transparent;
      }
      .ant-input {
        background-color: unset;
        color: $color-body-bg;
        border-color: $color-transparent;

        @media (max-width: 1024px) {
          font-size: 12px;
        }

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          opacity: 0.85;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          opacity: 0.85;
        }

        *::-ms-input-placeholder {
          /* Microsoft Edge */
          opacity: 0.85;
        }
      }
    }
  }

  .nav-right {
    #profile-dropdown {
      text-align: right;
      margin: 15px 0;

      a {
        color: #fff;

        @media (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
  }
}
