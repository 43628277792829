
.turnkey-authentication {
  margin-top: 35%;
  background-color: #fff;
  padding: 5%;

  .turnkey-logo {
    width: 100%;
    margin-bottom: 20px;
  }

  .ant-row {
    margin-bottom: 0px;
  }

  .ant-input-affix-wrapper {
    background-color: $color-white-smoke;
  }

  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input {
    background-color: $color-white-smoke;
  }

  .ant-checkbox {
    color: $color-transparent;
  }

  .ant-checkbox-input {
    background-color: $color-white-smoke;
  }

  .login-form-forgot {
    text-decoration: underline;
    color: $color-tiffany-blue;
  }


}
