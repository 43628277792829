.detail-page {
  .details-page-top-bar {
    .margin-right-10 {
      margin-right: 10px;
    }
    .margin-right-20 {
      margin-right: 20px;
    }
    @media (max-width: 1024px) {
      .sm-margin-top-5 {
        margin-top: 5px !important;
      }
      .sm-margin-right-20 {
        margin-right: 20px !important;
      }
    }
  }

  .note-well {
    padding: 24px;
    margin: 10px 0 0 0;
    background-color: white;
    border-top: 1px solid rgba(188, 188, 188, 0.5);
    flex-direction: column;
  }
  .module-list {
    margin: 20px 0 10px 0;

    .module-detail {
      display: flex;
      flex-direction: column;
      width: 194px;
      height: 125px;
      padding: 24px 20px;
      border-radius: 3px;
      color: white;
      background-color: $color-troller-grey;
      border-top: 3px solid rgba(black, 0.24);

      &:not(:last-child) {
        margin-right: 1%;
      }

      .padding-top-10 {
        padding-top: 10px;
      }

      &.active {
        &.pen_rider {
          background-color: $color-tiffany-blue;
        }

        &.movements {
          background-color: $movements-green;
        }

        &.truck_system {
          background-color: $truck-system-green;
        }
      }

      .ant-select-selection,
      .ant-select-arrow {
        background-color: inherit;
        border: inherit;
        color: white;
        box-shadow: none !important;
      }
      .ant-select-focused {
        box-shadow: none !important;
      }
    }
  }

  .sync-list {
    margin-top: 24px;
  }
  .ant-tabs-nav-wrap {
    justify-content: flex-end;
  }
  .admin-list {
    margin-top: 24px;
    .ant-table-body {
      .ant-table-tbody {
        .ant-table-row {
          td:last-child > span {
            float: right;
          }
        }
      }
    }
  }
}

.session-carousel-details {
  padding: 0 24px;
  flex: 1;
  font-size: 12px;
  width: 144px;

  .space-between-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}
